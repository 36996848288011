<template>
  <div id="container">
    <section id="register">
      <div class="wrap">
        <div class="register_step flex">
          <div class="step_box flex on">
            <div class="num">1</div>
            <p>塾生情報の登録</p>
          </div>
          <div class="step_box flex">
            <div class="num">2</div>
            <p>ご住所の登録</p>
          </div>
          <div class="step_box flex">
            <div class="num">3</div>
            <p>パスワード設定</p>
          </div>
          <div class="step_box flex">
            <div class="num">4</div>
            <p>入力内容の確認</p>
          </div>
          <div class="step_box flex">
            <div class="num">5</div>
            <p>初回月謝の<br>支払いへ進む</p>
          </div>
        </div>

        <form @submit="nextClicked">

          <div class="cts_box">
            <h2 class="sub_ttl"><span>塾生情報の登録</span></h2>
            <p class="reg_lead">
              <span class="blue bold">「*」のついている箇所は必須項目です。</span>
            </p>

            <form-error-box v-if="errors"/>

            <div class="input_set name">
              <div class="label">おなまえ<span>*</span></div>
              <div class="input_fields flex">
                <div class="field">
                  <div class="label">姓</div>
                  <input type="text" maxlength="16" v-model="new_user.last_name"
                         v-bind:class="{error: validation_errors.last_name}"
                         @input="new_user.last_name = replaceHalfToFull(new_user.last_name)"
                         placeholder="例：山田">
                  <validation-errors :errors="validation_errors.last_name"
                                     v-if="validation_errors.last_name"></validation-errors>
                </div>
                <div class="field">
                  <div class="label">名</div>
                  <input type="text" maxlength="16" v-model="new_user.first_name"
                         v-bind:class="{error: validation_errors.first_name}"
                         @input="new_user.first_name = replaceHalfToFull(new_user.first_name)"
                         placeholder="例：太郎">
                  <validation-errors :errors="validation_errors.first_name"
                                     v-if="validation_errors.first_name"></validation-errors>
                </div>
              </div>
            </div>

            <div class="input_set kana">
              <div class="label">フリガナ<span>*</span></div>
              <div class="input_fields flex">
                <div class="field">
                  <div class="label">姓（全角カナ）</div>
                  <input type="text" maxlength="16" v-model="new_user.last_name_kana"
                         v-bind:class="{error: validation_errors.last_name_kana}"
                         @input="new_user.last_name_kana = replaceHiraToKana(new_user.last_name_kana)"
                         placeholder="例：ヤマダ">
                  <validation-errors :errors="validation_errors.last_name_kana"
                                     v-if="validation_errors.last_name_kana"></validation-errors>
                </div>
                <div class="field">
                  <div class="label">名（全角カナ）</div>
                  <input type="text" maxlength="16" v-model="new_user.first_name_kana"
                         v-bind:class="{error: validation_errors.first_name_kana}"
                         @input="new_user.first_name_kana = replaceHiraToKana(new_user.first_name_kana)"
                         placeholder="例：タロウ">
                  <validation-errors :errors="validation_errors.first_name_kana"
                                     v-if="validation_errors.first_name_kana"></validation-errors>
                </div>
              </div>
            </div>

            <div class="input_set">
              <div class="label">性別<span>*</span></div>
              <div class="radio" v-bind:class="{error: validation_errors.gender}">
                <input id="radio01" type="radio" v-model="new_user.gender" value="male">
                <label for="radio01">男性</label>

                <input id="radio02" type="radio" v-model="new_user.gender" value="female">
                <label for="radio02">女性</label>

                <input id="radio03" type="radio" v-model="new_user.gender" value="secret">
                <label for="radio03">その他</label>
              </div>
              <validation-errors :errors="validation_errors.gender" v-if="validation_errors.gender"></validation-errors>
            </div>

            <div class="input_set birth">
              <div class="label">生年月日<span>*</span></div>
              <p>すべての欄に半角数字でご入力下さい。</p>
              <div class="input_fields flex">
                <div class="field">
                  <input type="text" v-model="new_user.birthday_year"
                         @input="new_user.birthday_year = replaceFullToHalf(new_user.birthday_year)"
                         v-bind:class="{error: validation_errors.birthday_year}" placeholder="年">
                  <validation-errors :errors="validation_errors.birthday_year"
                                     v-if="validation_errors.birthday_year"></validation-errors>
                </div>
                <div class="field">
                  <input type="text" v-model="new_user.birthday_month"
                         @input="new_user.birthday_month = replaceFullToHalf(new_user.birthday_month)"
                         v-bind:class="{error: validation_errors.birthday_month}" placeholder="月">
                  <validation-errors :errors="validation_errors.birthday_month"
                                     v-if="validation_errors.birthday_month"></validation-errors>
                </div>
                <div class="field">
                  <input type="text" v-model="new_user.birthday_day"
                         @input="new_user.birthday_day = replaceFullToHalf(new_user.birthday_day)"
                         v-bind:class="{error: validation_errors.birthday_day}" placeholder="日">
                  <validation-errors :errors="validation_errors.birthday_day"
                                     v-if="validation_errors.birthday_day"></validation-errors>
                </div>
              </div>
            </div>

            <div class="input_set birth">
              <div class="label">卒業見込み年/月<span>*</span></div>
              <div class="input_fields flex">
                <div class="field">
                  <input type="text" v-model="new_user.student_graduation_date_year"
                         @input="new_user.student_graduation_date_year = replaceFullToHalf(new_user.student_graduation_date_year)"
                         v-bind:class="{error: validation_errors.student_graduation_date_year}" placeholder="年">
                  <validation-errors :errors="validation_errors.student_graduation_date_year"
                                     v-if="validation_errors.student_graduation_date_year"></validation-errors>
                </div>
                <div class="field">
                  <input type="text" v-model="new_user.student_graduation_date_month"
                         @input="new_user.student_graduation_date_month = replaceFullToHalf(new_user.student_graduation_date_month)"
                         v-bind:class="{error: validation_errors.student_graduation_date_month}" placeholder="月">
                  <validation-errors :errors="validation_errors.student_graduation_date_month"
                                     v-if="validation_errors.student_graduation_date_month"></validation-errors>
                </div>
              </div>
            </div>

            <div class="input_set email">
              <div class="label">学校で発行されたメールアドレス<span>*</span><span v-if="new_user.student_not_email">（学生証登録とのいずれか必須）</span></div>
              <p>学生科入塾資格の確認のため、学校が発行した ac.jp, edu.jpなどの電子メールアドレスを入力してください。</p>
              <input type="text" class="email" v-bind:class="{error: validation_errors.student_email}"
                     v-model="new_user.student_email" placeholder="hukugan@millioneyes.ac.jp">
              <ValidationErrors :errors="validation_errors.student_email"
                                v-if="validation_errors.student_email"></ValidationErrors>
            </div>

            <div class="input_set card_select">
              <div class="checkbox">
                <input id="check03" type="checkbox" v-model="new_user.student_not_email" value="1"
                       v-on:change="studentNotEmailClicked()">
                <label for="check03" class="bold blue">
                  学校で発行したメールアドレスをお持ちでない場合は、学生証の登録により認証ができます。<br>
                  こちらにチェックをいれて学生証をアップロードしてください。
                </label>
              </div>
            </div>

            <div class="input_set card" v-if="new_user.student_not_email">
              <div class="label">学生証の登録<span>*</span>（学校発行のメールアドレスとのいずれか必須）</div>
              <p>お手持ちの学生証の画像（jpgまたはpng形式）をアップロードしてください。</p>
              <div class="card_img">
                <div v-if="new_user.student_card_image_url">
                  <img v-bind:src="new_user.student_card_image_url" id="student_card_image" alt="">
                </div>
                <div v-else>
                  <img src="/common/images/card_sample.png" alt="アップロードした画像が表示されます">
                </div>
              </div>
              <div class="btn_set flex">
                <div class="btn">
                  <label for="student_card_image1">画像をアップロード</label>
                  <input id="student_card_image1" type="file" accept="image/png,image/jpeg" v-on:change="studentCardImageSelected('student_card_image1')"/>
                </div>
                <div class="btn sp">
                  <label for="student_card_image2"><span class="icon_camera">カメラで撮影する</span></label>
                  <input id="student_card_image2" type="file" accept="image/png,image/jpeg" capture="camera" v-on:change="studentCardImageSelected('student_card_image2')"/>
                </div>
              </div>
              <!--
              <button type="button" class="btn photo_delete" v-on:click="studentCardImageDeleted()">削除</button>
              -->
              <ValidationErrors :errors="validation_errors.student_card_image"
                                v-if="validation_errors.student_card_image"></ValidationErrors>
            </div>

            <div class="input_set email">
              <div class="label">ユーザーネーム（表示名）<span>*</span></div>
              <p>複眼経済塾WEBサイト上で他の塾生に表示されるお名前です。ログイン後に「マイページ」で変更できます。</p>
              <input type="text" class="nickname" placeholder="" v-bind:class="{error: validation_errors.humhub_username}"
                     v-model="new_user.humhub_username">
              <ValidationErrors :errors="validation_errors.humhub_username"
                                v-if="validation_errors.humhub_username"></ValidationErrors>
            </div>

            <div class="input_set email">
              <div class="label">連絡先メールアドレス<span>*</span></div>
              <input type="text" class="email" v-bind:class="{error: validation_errors.email}"
                     v-model="new_user.email">
              <ValidationErrors :errors="validation_errors.email"
                                v-if="validation_errors.email"></ValidationErrors>
            </div>

            <div class="input_set email">
              <div class="label">連絡先メールアドレス(確認)<span>*</span></div>
              <input type="text" class="email" v-bind:class="{error: validation_errors.email_confirmation}"
                     v-model="new_user.email_confirmation">
              <ValidationErrors :errors="validation_errors.email_confirmation"
                                v-if="validation_errors.email_confirmation"></ValidationErrors>
            </div>

            <div class="input_set phone">
              <div class="label">連絡先電話番号<span>*</span></div>
              <p>（半角数字で日中連絡の取れるお電話番号をご入力下さい。）</p>
              <input type="tel" v-model="new_user.phone_number" v-bind:class="{error: validation_errors.phone_number}"
                     @input="new_user.phone_number = replaceFullToHalf(new_user.phone_number)"
                     placeholder="例：09012345678">
              <validation-errors :errors="validation_errors.phone_number"
                                 v-if="validation_errors.phone_number"></validation-errors>
            </div>

            <p class="alnC sp_alnL mb20">
              すべての項目に入力したらこちらのボタンを押してください。
            </p>
            <div class="button_wrap">
              <input type="submit" class="submit light arrow" value="住所の登録">
            </div>
          </div>

        </form>

      </div><!--//.wrap-->
    </section>
  </div><!--//＃container-->
</template>

<script>
import Libraries from '@/components/Libraries.vue'
import ValidationErrors from '@/components/ValidationErrors.vue'
import FormErrorBox from '@/elements/FormErrorBox.vue'

export default {
  mixins: [Libraries],
  components: {
    ValidationErrors,
    FormErrorBox
  },
  data() {
    return {
      new_user: this.$store.state.user && this.$store.state.user.init ? this.$store.state.user : {
        init: true,
        id: null,
        last_name: null,
        first_name: null,
        last_name_kana: '',
        first_name_kana: null,
        humhub_username: null,
        birthday_year: null,
        birthday_month: null,
        birthday_day: null,
        student_graduation_date_year: null,
        student_graduation_date_month: null,
        student_email: null,
        student_not_email: false,
        student_card_image: null,
        student_card_image_url: null,
        email: null,
        email_confirmation: null,
        phone_number: null,
        gender: null,
        male: null,
        zip: null,
        prefecture: null,
        address1: null,
        address2: null,
        address3: null,
        opt_zip: null,
        opt_address1: null,
        opt_address2: null,
        opt_address3: null,
        enable_primary_address: null,
        password: null,
        password_confirmation: null,
        questionnaire_ids: null,
      },
      validation_errors: {},
      errors: null,
    }
  },

  mounted() {
    if (!this.$store.state.user) {
      this.$router.push({
        name: 'UsersRegistrationStudent',
        params: this.$route.params
      })
      return
    }
    //console.log(this.$store.state.user)
    Object.assign(this.new_user, this.$store.state.user)
    this.$store.state.user = this.new_user
  },

  methods: {
    nextClicked(event) {
      const keys = [
        'first_name', 'last_name', 'first_name_kana', 'last_name_kana', 'humhub_username', 'gender',
        'birthday_year', 'birthday_month', 'birthday_day', 'phone_number', 'email', 'email_confirmation',
        'student_email', 'student_not_email', 'student_graduation_date_year', 'student_graduation_date_month', 'student_card_image',
      ]

      event.preventDefault()
      this.validation_errors = {}
      this.errors = null

      // 学生メアドがあれば学生証はなし
      //if (this.new_user.student_email) {
      //  this.new_user.student_card_image = this.new_user.student_card_image_url = null
      //  this.new_user.student_not_email = false
      //}

      this.$store.state.user = this.new_user

      this.axios
          .post(`${this.env.api_base_url}users/validate.json`, {
            user: this.new_user,
          })
          .then(() => {
            this.$router.push({
              name: 'UsersConfirmationStudentPage2',
              params: this.$route.params
            })
          })
          .catch((error) => {
            this.scrollTop()
            if (error.response.data.errors) {
              keys.forEach(key => {
                if (error.response.data.validation_errors[key]) {
                  this.validation_errors[key] = error.response.data.validation_errors[key]
                }
              })
              if (Object.keys(this.validation_errors).length === 0) {
                this.$router.push({
                  name: 'UsersConfirmationStudentPage2',
                  params: this.$route.params
                })
              } else {
                this.errors = true
              }
            }
            if (error.response.data && error.response.data.error) {
              // TODO: エラー処理
              // this.errors = [error.response.data.error]
            }
            this.$forceUpdate();
          });
    },

    studentNotEmailClicked() {
    },

    studentCardImageDeleted() {
      this.new_user.student_card_image_url = `${this.env.api_base_url}presets/empty.png`
      this.new_user.remove_student_card_image = true
    },

    studentCardImageSelected(id) {
      const params = new FormData();
      const fileSelectDom = document.getElementById(id)
      params.append('file', fileSelectDom.files[0])
      //params.append('token', this.$route.params.token)
      this.validation_errors.student_card_image = null
      this.new_user.student_card_image = null
      //this.new_user.student_card_image_url = null
      this.new_user.remove_student_card_image = false
      this.axios
          .post(`${this.env.api_base_url}users/student_card.json`, params)
          .then(response => {
            this.new_user.student_card_image = response.data.cache_name
            this.new_user.student_card_image_url = response.data.url
          })
          .catch(error => {
            if (error.response.data && error.response.data.errors) {
              // console.log(error.response.data);
              this.validation_errors.student_card_image = error.response.data.errors
            }
          });
    }
  }
}
</script>
